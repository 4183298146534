<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <p class="p-title">基础信息</p>
            <el-form ref="form" v-bind:rules="rules" v-bind:model="form" label-position="left" label-suffix=":" size="large" label-width="110px" class="dialog_box">
                <el-form-item label="活动名称" prop="name">
                    <el-input v-model="form.name" placeholder="请输入签到活动名称" style="margin-bottom:10px;width: 200px"></el-input>
                </el-form-item>
                <el-form-item label="起止时间" prop="time">
                    <el-date-picker ref="drawDate" :picker-options="pickerOptions" style="margin-bottom:10px;" v-model="form.time" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                </el-form-item>
              <el-form-item label="签到奖励" prop="" class="sign-reward-row">
                <el-col :span="5">
                  <el-input label="" v-model="form.first_sign_integral" placeholder="请输入签到首次奖励积分数量" style="margin-bottom:10px;width: 200px;">
                    <template slot="prepend">首次奖励积分:</template>
                  </el-input>
                </el-col>
                <el-col :span="5">
                  <span></span>
                  <el-input v-model="form.normal_sign_integral" placeholder="请输入签到日常奖励积分数量" style="margin-bottom:10px;margin-left: 30px">
                  <template slot="prepend">日常奖励积分:</template>
                </el-input>
                </el-col>
              </el-form-item>
              <el-form-item label="兑换额度(分)" prop="position">
                  <el-input v-model="form.complete_integral" placeholder="请输入兑换额度的积分数"
                            style="margin-bottom:10px;"></el-input>
              </el-form-item>
              <el-form-item label="循环签到次数" prop="sign_cycle_times">
                <el-input v-model="form.sign_cycle_times" placeholder="请输入循环签到次数" style="margin-bottom:10px;width: 200px"></el-input>
              </el-form-item>
                <el-form-item label="排序" prop="r">
                    <el-input v-model="form.r" placeholder="请输入循环签到次数" style="margin-bottom:10px;width: 200px"></el-input>
                </el-form-item>

                <el-form-item label="状态" prop="state">
                    <el-radio-group v-model="form.state">
                        <el-radio :label="2">上线</el-radio>
                        <el-radio :label="1">下线</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="默认签到活动" prop="is_default">
                    <el-radio-group v-model="form.is_default">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="活动介绍" prop="desc">
                    <quill-editor class="editor" ref="myTextEditor" v-model="form.desc" :options="editorOption">
                    </quill-editor>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="saveData">保 存</el-button>
                    <el-button @click="cancel">取 消</el-button>
                </el-form-item>
                <!-- 选择图片对话框 -->
                <div class="img">
                    <Picture :ImgVisible="ImgVisible" @toggleImg="toggleImg" @getImg="getImg"></Picture>
                </div>
            </el-form>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import loadEvents from '@/utils/loading'
import Imgupload from '@/components/Imgupload';
import {deal} from '@/common/main';
import {request} from '@/common/request';
import bus from '@/common/bus';
import Picture from '@/components/Picture';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import {quillEditor} from 'vue-quill-editor';

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  ['blockquote', 'code-block'],

  [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
  [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
  [{ 'direction': 'rtl' }],                         // text direction

  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  [{ 'font': [] }],
  [{ 'align': [] }],
  ['image'],
  ['clean']                                         // remove formatting button
]


let loads = new loadEvents();
export default {
    name: 'StoreInfo',
    components: {
      Breadcrumb,
      Imgupload,
      Picture,
      quillEditor,
    },
    data() {
        return {
            ImgVisible: false,
            text: 'image',
            mode: 'add',
            menuList: ['营销', '签到活动','添加'],
            loading: false,
            content:"",
            rules: {
                name: [{
                    required: true,
                    message: '活动名称不能为空',
                    trigger: 'blur'
                }],
                time: [{
                    required: true,
                    message: '活动时间不能为空',
                    trigger: 'blur'
                }],

                first_sign_integral: [{
                    required: true,
                    message: '请输入首次签到发放积分',
                    trigger: 'blur'
                }],
                normal_sign_integral: [{
                    required: true,
                    message: '请输入日常签到发放积分',
                    trigger: 'blur'
                }],
                complete_integral: [{
                    required: true,
                    message: '请输入兑换所需积分',
                    trigger: 'blur'
                }],
                sign_cycle_times: [{
                    required: true,
                    message: '请输入循环签到周期的次数',
                    trigger: 'blur'
                }],
                r: [{
                    required: true,
                    message: '请输入排序值',
                    trigger: 'blur'
                }],
                desc: [{
                    required: true,
                    message: '请输入活动规则',
                    trigger: 'blur'
                }],
                is_default: [{
                    required: true,
                    message: '请选择是否为默认活动',
                    trigger: 'blur'
                }],
                state: [{
                    required: true,
                    message: '请选择状态',
                    trigger: 'blur'
                }],
            },
            form: {
                desc: "",
                state: 1,
                is_default: 0,
                r:0,
                sign_cycle_times:7,
                complete_integral:200,
                normal_sign_integral:5,
                first_sign_integral:10,
                name:"签到活动",
                time:["2021-07-01 00:00:00","2099-12-31 23:59:59"]
            },
            header: {},
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
            positionList: [],
            thumbs: [],
          editorOption: {
            placeholder: '请输入活动规则',
            content: this.content,
            modules: {
              toolbar: {
                container: toolbarOptions,  // 工具栏
                handlers: {
                  'image': function (value) {
                    if (value) {
                      // document.querySelector('#detail-img').click()
                      document.querySelector('.select-uploader input').click();
                    } else {
                      this.quill.format('image', false)
                    }
                  }
                }
              }
            }
          },
        };
    },
    created() {
        this.header.token = localStorage.getItem('token');
        this.header.platform = 'admin';
    },
    methods: {
        //增加编辑
        saveData() {
            this.form.thumbs = this.thumbs.join(',')
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                      this.form.ctime = deal.timestamp(this.form.time[0]);
                      this.form.etime = deal.timestamp(this.form.time[1] );
                        request.post('/activity/sign/activity/add', this.form).then(ret => {
                            if (ret.code === 1) {
                                this.$message.success('新增成功');
                                this.$refs.form.clearValidate()
                                bus.$emit('close_current_tags');
                               this.$router.push({ path: '/activity-SignActivityList' })
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$refs.form.clearValidate();
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },
        // 取消按钮
        cancel() {
            bus.$emit('close_current_tags');
            this.$router.push({ path: '/activity-SeckillSessionList' })
        },
        //
        handleCheckedHoursChange(val,before){
        },
        // 添加规格
        addType() {
            this.form.tyList.push({
                number: null,
                price: null
            })
            this.$forceUpdate()
        },
        // 删除规格
        deleteTyList(index) {
            this.form.tyList.splice(index, 1)
            this.$forceUpdate()
        },
        // 图片上传之前
        beforeLogoUpload(file) {
            loads.open(`.img1 .el-upload`, '上传中')
            const _this = this
            return deal.imageFormat(file, _this)
        },
        beforeBannerUpload(file) {
            loads.open(`.img2 .el-upload`, '上传中')
            const _this = this
            return deal.imageFormat(file, _this)
        },
        beforePosterUpload(file) {
            loads.open(`.img3 .el-upload`, '上传中')
            const _this = this
            return deal.imageFormat(file, _this)
        },
        beforeCoverUpload(file) {
            loads.open(`.img4 .el-upload`, '上传中')
            const _this = this
            return deal.imageFormat(file, _this)
        },
        // 图片上传成功
        handleLogoSuccess(res, file) {
            if (res.code == 1) {
                loads.close()
                this.$set(this.form, 'logo', res.data.uri);
            }
        },
        handleBannerSuccess(res, file) {
            if (res.code == 1) {
                loads.close()
                this.$set(this.form, 'image', res.data.uri);
            }
        },
        handlePosterSuccess(res, file) {
            if (res.code == 1) {
                loads.close()
                this.$set(this.form, 'poster', res.data.uri);
            }
        },
        handleCoverSuccess(res, file) {
            if (res.code == 1) {
                loads.close()
                this.$set(this.form, 'thumbs', res.data.uri);
            }
        },
        // 选择位置
        changePosition() {
            deal.getconf('signActivity', this)
        },

        toggleImg(val) { // 关闭 img对话框
            this.ImgVisible = val;

        },
        choiceImg(text) {
            this.text = text
            this.ImgVisible = true
        },
        getImg(url) {// 获取选择的img
            if (this.text === 'many') {
                if (this.thumbs.length < 9) {
                    this.thumbs.push(url)
                }
                else {
                    this.$message({ type: 'error', message: '最多上传九张图片' })
                }
            } else {
                this.$set(this.form, this.text, url);
            }
        },
        changeImg(val) {
            this.ImgVisible = val
        },
        changetext(val) {
            this.text = val
        },
    }
};
</script>

<style scoped>
.type {
    width: 1200px;
    display: flex;
    flex-wrap: wrap;
}
.type_box {
    display: flex;
}
.box {
    width: 240px;
    padding: 20px 30px 0;
    border: 1px solid #eeeeee;
    margin-right: 20px;
}
.box >>> .el-form-item {
    margin-bottom: 20px;
}
.type_box .box >>> .el-input--large .el-input__inner {
    width: 150px;
    margin-right: 10px;
}
.el-textarea >>> .el-textarea__inner {
    width: 500px !important;
    height: 200px;
}
.p-title {
    font-size: 20px;
    font-weight: 900;
}
.dialog_box {
    padding: 30px;
}
.span_gray {
    font-size: 12px;
    color: gray;
}
.dialog_box >>> .el-select .el-input--large .el-input__inner {
    width: 400px;
}
.dialog_box >>> .el-input--large .el-input__inner {
    width: 201%;
}
.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}
.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}
.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}

.dialog_box .up {
    margin-top: 20px;
}
.dialog_box .img1 .avatar-uploader-icon {
    font-size: 28px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}
.dialog_box .img1 .avatar {
    font-size: 28px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}
.dialog_box .img2 .avatar-uploader-icon {
    font-size: 28px;
    width: 150px;
    height: 60px;
    line-height: 60px;
}
.dialog_box .img2.avatar {
    font-size: 28px;
    width: 150px;
    height: 60px;
    line-height: 60px;
}
.dialog_box .img3 .avatar-uploader-icon {
    font-size: 28px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}
.dialog_box .img3.avatar {
    font-size: 28px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}

.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}

.input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
}

.container {
    background: #ffffff;
}

.form-input >>> .el-input__inner {
    width: 500px;
}

.el-input--small .el-input__inner {
    width: 500px;
}
/* 选择链接样式 */
.dialog_box .el-button {
    margin-left: 220px;
}
.type_box .el-button {
    margin-left: 20px;
}


.sign-reward-row .el-input__inner{
  width: 200px;
}
</style>
